
.auth-page {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
}

.auth-page div.logo-login {
    background-color: #25325b;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 80px 0;
}

.auth-page div.logo-login img {
    margin-left: -18px;
}

.form-signin {
    width: 554px;
    margin: auto;
    border-radius: 32px;
    display: grid;
    grid-template-columns: 320px 1fr;
    background: #fff;
    padding: 0;
    box-shadow: 1px 2px 18px 0px rgba(0,0,0,0.29);
    -webkit-box-shadow: 1px 2px 18px 0px rgba(0,0,0,0.29);
    -moz-box-shadow: 1px 2px 18px 0px rgba(0,0,0,0.29);
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.auth-page .custom-label {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px 12px;
    font-size: 13px;
    margin-top: 18px;
    letter-spacing: 1px;
}

.auth-page h1 {
    font-size: 13px;
    letter-spacing: .5px;
    font-weight: 700 !important;
    color: #25325b;
    margin-top: 45px;
    margin-left: -16px;
}

.auth-page .form-control {
    border: none !important;
    border-bottom: 1px solid #47bbc5 !important;
    border-radius: 0;
    padding: 0.1rem 0.3rem;
    font-size: 11px;
}

.auth-page .form-control::placeholder {
    font-size: 11px;
    color: #25325b;
    letter-spacing: .4px;
}

.auth-page a.create-account {
    font-size: 8px;
    color: #25325b;
    text-decoration: none;
    margin-top: 5px;
    display: block;
}

.auth-page a.forget-password {
    font-size: 8px;
    color: #25325b;
    text-decoration: none;
    border-bottom: 1px solid #47bbc5;
    padding-bottom: 3px;
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 38px;
}