.bg-dashboard img {
    width: 100%;
}

.chart {
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    padding: 1rem;
}

.dashboard-stats {
    text-align: center;
}

.dashboard-stats h4 {
    font-size: 12px;
    text-transform: uppercase;
}

.dashboard-stats h6 {
    margin-bottom: 30px;
    font-size: 30px;
}

.dashboard-stats .stats-donors h6 {
    color: #4bb9c4;
}

.dashboard-stats .stats-receivers h6 {
    color: #adc90e;
}

.dashboard-stats .stats-pairings h6, .dashboard-stats .stats-born-alive h6 {
    color: #25325b;
}