.pairing-add-page .photo-container {
    margin: 0 auto;
    width: 80%;
}

.paired-person-card ul, .pairing-aspects ul {
    padding: 0 5px;
}

.paired-person-card li, .pairing-aspects li {
    list-style: none;
    font-size: 11px;
    line-height: 1.3;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    color: #666666;
}

.paired-person-card h6, .pairing-aspects h6 {
    font-size: 12px;
    color: #666666;
}