.photo-container {
    width: 100%;
    position: relative;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 20px;
    background: linear-gradient(-180deg, rgba(37,50,91,1) 0%, rgba(75,185,196,1) 31%, rgba(67,64,64,1) 31%, rgba(67,64,64,1) 100%);
    padding: 28px 0;
    padding-bottom: 12px;
    box-shadow: 1px 2px 18px 0px rgba(0,0,0,0.29);
    -webkit-box-shadow: 1px 2px 18px 0px rgba(0,0,0,0.29);
    -moz-box-shadow: 1px 2px 18px 0px rgba(0,0,0,0.29);
}

.photo-container.no-gradient {
    background: rgba(67,64,64,1) !important;
}

.photo-container .stared {
    position: absolute;
    top: 8px;
    right: 12px;
    font-size: 20px;
    color: #4bb9c4;
}

.photo-container .checkbox-container {
    position: absolute;
    top: 8px;
    right: 12px;
    font-size: 20px;
}

.photo-label {
    font-size: 11px;
    color: #4bb9c4;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    margin-top: 8px;
}

.photo-sub-label {
    font-size: 11px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    margin-top: 8px;
    min-height: 14px;
}

.photo-content {
    background-position: center center;
    background-size: cover;
    height: 115px;
    border-radius: 50%;
    width: 115px;
    margin: 0 auto;
    border: 4px solid #fff;
    background-color: rgba(67,64,64,1);
    position: relative;
}

.photo-content .button-container {
    font-size: 1.5rem;
    position: absolute;
    top: 29%;
    width: 84%;
    left: 8%;
}

.photo-content .button-container button {
    text-transform: uppercase;
    font-size: 10px;
    border-radius: 11px;
}

.photo-container .icon-edit:hover {
    cursor: pointer;
}